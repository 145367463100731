/* imessage like chat start */
.imessage {
  background-color: #fff;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  font-family: 'SanFrancisco';
  font-size: 1.25rem;
  margin: 0 auto 1rem;
  max-width: 600px;
  padding: 0.5rem 1.5rem;
  margin-top: 10rem;
}

.imessage p {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
}

.imessage p::before,
.imessage p::after {
  bottom: -0.1rem;
  content: '';
  height: 1rem;
  position: absolute;
}

.imessage p.from-me {
  align-self: flex-end;
  background-color: #248bf5;
  color: #fff;
}

.imessage p.from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #248bf5;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

.imessage p.from-me::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

.imessage p[class^='from-'] {
  margin: 0.5rem 0;
  width: fit-content;
}

.imessage p.from-me ~ p.from-me {
  margin: 0.25rem 0 0;
}

.imessage p.from-me ~ p.from-me:not(:last-child) {
  margin: 0.25rem 0 0;
}

.imessage p.from-me ~ p.from-me:last-child {
  margin-bottom: 0.5rem;
}

.imessage p.from-them {
  align-items: flex-start;
  background-color: #e5e5ea;
  color: #000;
}

.imessage p.from-them:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid #e5e5ea;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

.imessage p.from-them::after {
  background-color: #fff;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}

.imessage p.from-them.green:before {
  border-left: 1rem solid green;
}

.imessage p.from-them.red:before {
  border-left: 1rem solid red;
}

.imessage p.from-me.red::before {
  border-right: 1rem solid red;
}

.imessage p.from-me.green::before {
  border-right: 1rem solid green;
}

.imessage p.red {
  background-color: red;
}

.imessage p.green {
  background-color: green;
  color: white;
}

.imessage p[class^='from-'].emoji {
  background: none;
  font-size: 2.5rem;
}

.imessage p[class^='from-'].emoji::before {
  content: none;
}

.imessage .no-tail::before {
  display: none;
}

.imessage .margin-b_none {
  margin-bottom: 0 !important;
}

.imessage .margin-b_one {
  margin-bottom: 1rem !important;
}

.imessage .margin-t_one {
  margin-top: 1rem !important;
}

/* general styling */
@font-face {
  font-family: 'SanFrancisco';
  src: url('https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff2')
      format('woff2'),
    url('https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff')
      format('woff');
}

.imessage {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: normal;
  margin: 0;
}

@media screen and (max-width: 800px) {
  .imessage {
    font-size: 1.05rem;
    margin: 0 auto 1rem;
    max-width: 600px;
    padding: 0.25rem 0.875rem;
  }

  .imessage p {
    margin: 0.5rem 0;
  }
}
/* imessage like chat end */
